import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Digital Accessibility for Developers`}</h1>
    <h2>{`2022-05-27 at 5:45pm`}</h2>
    <p><em parentName="p">{`Duration: 1 hour`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220528T004500Z%2F20220528T014500Z&details=Topics%3A%20Disabilities%20and%20Standards%2C%20HTML5%20as%20A11y%2C%20Images%2C%20Design%20and%20Color%2C%20Multimedia%20and%20Motion%2C%20Forms%2C%20Using%20ARIA%0A%0AStudents%20will%20gain%20an%20introduction%20to%20Digital%20Accessibility%2C%20disability%20in%20the%20digital%20context%2C%20and%20the%20WCAG%20standards%20that%20govern%20what%20is%20and%20what%20is%20not%20considered%20accessible%20code.%0A%0AWe%20will%20then%20go%20deeper%20and%20ask%2C%20%E2%80%9CHow%20do%20we%20as%20developers%20make%20products%20with%20accessibility%20in%20mind%3F%E2%80%9D%20We%E2%80%99ll%20cover%20a%20number%20of%20topics%20including%20HTML5%2C%20Images%2C%20Forms%20and%20more.%20We%E2%80%99ll%20talk%20high%20level%2C%20with%20examples%2C%20then%20dive%20into%20the%20code.%0A%0AWe%E2%80%99ll%20conclude%20with%20resources%20for%20how%20developers%20can%20specialize%20in%20this%20growing%20and%20rewarding%20Software%20Engineering%20niche.&location=http%3A%2F%2Flovelace-labs.adadev.org%2F&text=Digital%20Accessibility%20for%20Developers"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=Topics%3A%20Disabilities%20and%20Standards%2C%20HTML5%20as%20A11y%2C%20Images%2C%20Design%20and%20Color%2C%20Multimedia%20and%20Motion%2C%20Forms%2C%20Using%20ARIA%0A%0AStudents%20will%20gain%20an%20introduction%20to%20Digital%20Accessibility%2C%20disability%20in%20the%20digital%20context%2C%20and%20the%20WCAG%20standards%20that%20govern%20what%20is%20and%20what%20is%20not%20considered%20accessible%20code.%0A%0AWe%20will%20then%20go%20deeper%20and%20ask%2C%20%E2%80%9CHow%20do%20we%20as%20developers%20make%20products%20with%20accessibility%20in%20mind%3F%E2%80%9D%20We%E2%80%99ll%20cover%20a%20number%20of%20topics%20including%20HTML5%2C%20Images%2C%20Forms%20and%20more.%20We%E2%80%99ll%20talk%20high%20level%2C%20with%20examples%2C%20then%20dive%20into%20the%20code.%0A%0AWe%E2%80%99ll%20conclude%20with%20resources%20for%20how%20developers%20can%20specialize%20in%20this%20growing%20and%20rewarding%20Software%20Engineering%20niche.&enddt=2022-05-28T01%3A45%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org%2F&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-05-28T00%3A45%3A00%2B00%3A00&subject=Digital%20Accessibility%20for%20Developers"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p><strong parentName="p">{`Topics:`}</strong>{` Disabilities and Standards, HTML5 as A11y, Images, Design and Color, Multimedia and Motion, Forms, Using ARIA`}</p>
    <p>{`Students will gain an introduction to Digital Accessibility, disability in the digital context, and the WCAG standards that govern what is and what is not considered accessible code.`}</p>
    <p>{`We will then go deeper and ask, “How do we as developers make products with accessibility in mind?” We’ll cover a number of topics including HTML5, Images, Forms and more. We’ll talk high level, with examples, then dive into the code.`}</p>
    <p>{`We’ll conclude with resources for how developers can specialize in this growing and rewarding Software Engineering niche.`}</p>
    <h2>{`Lē Silveus McNamara`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d5ac9f10795effd3031ded11c093c853/f93b5/Le-Silveus-McNamara-thumbnail.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMEAv/aAAwDAQACEAMQAAABlToyVGBhN1HkgBp//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAERIxMv/aAAgBAQABBQI5s4DKYgssKMXp9z//xAAZEQABBQAAAAAAAAAAAAAAAAAAAhAxQUL/2gAIAQMBAT8B01CYP//EABgRAAIDAAAAAAAAAAAAAAAAAAABEDFB/9oACAECAQE/Acl2f//EABsQAAEEAwAAAAAAAAAAAAAAAAABAhASICGR/9oACAEBAAY/Aqt0Vd3FBI//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhEFExQZH/2gAIAQEAAT8hV1Nw0tXG2BmCtQSQFWK3dqe6Gf/aAAwDAQACAAMAAAAQU+BD/8QAGhEAAgIDAAAAAAAAAAAAAAAAABEBECExQf/aAAgBAwEBPxBrKa6NJ//EABkRAAIDAQAAAAAAAAAAAAAAAAARECExQf/aAAgBAgEBPxBOkdNj/8QAHRABAAIBBQEAAAAAAAAAAAAAAREhABAxQWGBsf/aAAgBAQABPxAMLIDHqcV4ZEODo0i2TzkykLV7pMQqfgMrbsR+5uc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lē's thumbnail",
            "title": "Lē's thumbnail",
            "src": "/static/d5ac9f10795effd3031ded11c093c853/f93b5/Le-Silveus-McNamara-thumbnail.jpg",
            "srcSet": ["/static/d5ac9f10795effd3031ded11c093c853/f93b5/Le-Silveus-McNamara-thumbnail.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Lē, pronounced “Lee”, (they/them) brings a diverse background and skillset to Digital Accessibility. An alumna the `}<a parentName="p" {...{
        "href": "https://www.gracehopper.com/"
      }}>{`Grace Hopper coding academy`}</a>{` in NY, Lē discovered their passion for accessibility in 2018 after a career transition from Public Relations and community work to Software Engineering.`}</p>
    <p>{`Their most recent roles include Accessibility Engineer at TPGi (current), Accessibility Remediation Engineer with Smartsheet.com and Principal Accessibility Developer and Founder of Larunda Accessibility Solutions. Lē is passionate about accessibility, for the neurodivergent in particular, and identifies as a member of the neurodivergent community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      